import * as React from "react";

// React
import { useState, useEffect } from "react";
import { AdvancedImage } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";

// SVG
import Logo from "../images/logo/logo.inline.svg";
import LogoLetters from "../images/logo/logo-letters.inline.svg";

// Heroicons
import { ArrowNarrowDownIcon } from "@heroicons/react/outline";

// Shared Components
import TextModal from "../components/shared/modal/Modal";
import LoadingButton from "../components/shared/loading-button/LoadingButton";
import Footer from "../components/shared/footer/Footer";
import Header from "../components/shared/header/Header";
import Seo from "../components/seo";

// Services
import { contactSubmission } from "../services/contactAPI";

export default function Contact() {
  const [selected, setSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [icon, setIcon] = useState("");
  const [description, setDescription] = useState("");
  const [largeScreen, setLargeScreen] = useState(true);
  const [loading, setLoading] = useState(false);

  // Modal
  function toggleModalOpen() {
    setIsOpen(!isOpen);
  }

  //ADD LISTENER TO CHECK THE SCREEN SIZE
  function checkScreenSize() {
    if (window.innerWidth <= 768) {
      setLargeScreen(false);
    } else {
      setLargeScreen(true);
    }
  }

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  // Create a Cloudinary instance and set your cloud name.
  const cld = new Cloudinary({
    cloud: {
      cloudName: "kirkwork",
    },
  });
  // define our cloudinary images
  const HeaderImg = cld
    .image("v1646062028/Aritae/Contact/contact-mountain-lake_veugow.jpg")
    .quality("auto");
  const HeaderMobile = cld
    .image("v1646077211/Aritae/Contact/contact-mountain-lake-mobile_ydnlnw.jpg")
    .quality("auto");

  // Which contact type is selected
  const handleSelect = (event) => {
    event.preventDefault();
    setSelected(event.target.value);
  };

  //email sent from contact form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const email = event.target.email.value;
    const firstName = event.target.firstName.value;
    const lastName = event.target.lastName.value;
    const phone = event.target.phone.value;
    const subject = event.target.subject.value;
    const message = event.target.message.value;

    let postBody = JSON.stringify({
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      Phone: phone,
      Subject: subject,
      ContactType: selected,
      Message: message,
      PageName: "Contact",
    });

    contactSubmission(postBody).then(async (response) => {
      const data = await response;
      if (data.dbSuccess === true && data.endUserEmailSuccess === true) {
        // modal success
        setIsOpen(true);
        setIcon("success");
        setDescription(
          "Thanks for your interest in ARITAE! Please check your inbox for updates."
        );
        setLoading(false);
        event.target.reset();
      } else {
        // modal error
        setIsOpen(true);
        setIcon("failure");
        setDescription("Something went wrong. Please try again.");
        setLoading(false);
        event.target.reset();
      }
    });
  };

  return (
    <div id="root">
      <Header />
      <Seo title="Contact" description="Contact the ARITAE team." />
      {/* Modal */}
      <TextModal
        isOpen={isOpen}
        close={toggleModalOpen}
        icon={icon}
        description={description}
      />
      {/* START HERO SECTION */}
      <div className="h-screen relative">
        <div className="absolute inset-0">
          <div className="absolute flex-1 top-0 inset-0">
            <AdvancedImage
              cldImg={largeScreen ? HeaderImg : HeaderMobile}
              alt="blue lake with mountains overlooking and a blue sky"
              className="h-full w-full object-cover"
            />
          </div>
        </div>
        <div className="relative">
          <div className="flex flex-col h-screen items-center justify-center">
            <h1 className="text-center text-4xl font-extrabold sm:text-5xl lg:text-6xl">
              <span className="block text-white text-2xl pb-6">CONTACT</span>
            </h1>
            <div className={"flex flex-col items-center justify-center my-12"}>
              <Logo fill={"white"} className={"h-32 w-auto mx-auto pb-2"} />
              <LogoLetters fill={"white"} className={"h-auto w-48 mx-auto"} />
            </div>
            <p
              className={
                "text-white max-w-7xl px-16 text-center font-bold text-4xl mt-4"
              }
            >
              “TELL ME AND I FORGET. SHOW ME AND I REMEMBER. INVOLVE ME AND I
              UNDERSTAND.”
            </p>
            <div className="hidden lg:flex mt-10 max-w-sm mx-auto justify-center">
              <ArrowNarrowDownIcon className={"h-7 text-white"} />
            </div>
          </div>
        </div>
      </div>
      {/* END HERO SECTION */}
      <div className="bg-slate-600 flex justify-center py-24">
        <div className="w-full lg:w-7/12">
          <div className="flex flex-col items-start justify-start w-full h-full p-10 lg:p-16 xl:p-24">
            <h4 className="w-full text-3xl text-white font-bold mb-2">
              CONTACT
            </h4>
            <p className="text-sm text-white">info@aritae.com</p>
            <p className="text-sm text-white">
              4000 ARITAE Way, Syracuse, NY 13202
            </p>
            <form onSubmit={handleSubmit} className="relative h-full w-full">
              <div className="relative w-full mt-10 space-y-4">
                <div className="relative">
                  <label htmlFor={"firstname"}>
                    <input
                      type="text"
                      className="block w-full border-none p-3 text-sm placeholder-white bg-white bg-opacity-50"
                      placeholder="First Name *"
                      name="firstName"
                      required
                    />
                  </label>
                </div>
                <div className="relative">
                  <label htmlFor={"lastname"}>
                    <input
                      type="text"
                      className="block w-full border-none p-3 text-sm placeholder-white bg-white bg-opacity-50"
                      placeholder="Last Name *"
                      name="lastName"
                      required
                    />
                  </label>
                </div>
                <div className="relative flex justify-between">
                  <label htmlFor={"email"} className={"inline-block w-6/12"}>
                    <input
                      type="email"
                      className=" w-full border-none p-3 text-sm  bg-white bg-opacity-50 placeholder-white"
                      placeholder="Email Address *"
                      required
                      name="email"
                      id="email"
                    />
                  </label>
                  <label
                    htmlFor={"phone"}
                    className={"inline-block w-6/12 ml-2"}
                  >
                    <input
                      type="text"
                      className="w-full border-none p-3 text-sm  bg-white bg-opacity-50 placeholder-white"
                      placeholder="Phone Number"
                      name="phone"
                      id={"phone"}
                    />
                  </label>
                </div>
                <div className="relative flex justify-between">
                  <label htmlFor={"subject"} className={"inline-block w-6/12"}>
                    <input
                      type="text"
                      className="w-full border-none p-3 text-sm  bg-white bg-opacity-50 placeholder-white"
                      placeholder="Subject"
                      name="subject"
                      id={"subject"}
                    />
                  </label>
                  <label
                    htmlFor={"dropdown"}
                    className={"inline-block w-6/12 ml-2"}
                  >
                    <select
                      onChange={handleSelect}
                      id="dropdown"
                      className=" placeholder-white text-white w-full border-none p-3 text-sm  bg-white bg-opacity-50"
                    >
                      <option value="NA" defaultValue hidden>
                        I am a
                      </option>
                      <option value="player">Player</option>
                      <option value="coach">Coach</option>
                      <option value="parent">Parent</option>
                      <option value="other">Other</option>
                    </select>
                  </label>
                </div>
                <div className="relative">
                  <label htmlFor={"message"}>
                    <textarea
                      type="textarea"
                      className="block w-full border-none p-3 text-sm  bg-white bg-opacity-50 placeholder-white"
                      placeholder="Message"
                      name="message"
                      id={"message"}
                    />
                  </label>
                </div>
                <div className="relative">
                  {loading ? (
                    <LoadingButton />
                  ) : (
                    <button className="inline-block w-1/2 md:w-1/4 p-3 text-sm font-bold text-center text-gray-600 transition duration-200 bg-white hover:bg-gray-100 ease">
                      SUBMIT
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
